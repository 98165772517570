import { FiltersState, PositionsMap } from "./Models";
import { Portfolio } from "./Rebalance/Portfolio";

interface LocalStorageKeys {
  PortfolioAnalyzer_Filters: FiltersState;
  PortfolioAnalyzer_Portfolios: Portfolio[];
  PortfolioAnalyzer_Positions: PositionsMap;
}

const defaultValues: LocalStorageKeys = {
  PortfolioAnalyzer_Filters: {
    dividendMetadata: "",
    enableIgnoredPositions: true,
    enableSelectedAccounts: true,
    selectedAccountsKeys: [],
    ignoredPositionKeys: [],
  },
  PortfolioAnalyzer_Portfolios: [],
  PortfolioAnalyzer_Positions: {},
};

export function getItem<T extends keyof LocalStorageKeys>(
  key: T
): LocalStorageKeys[T] {
  try {
    return JSON.parse(
      localStorage.getItem(key) || JSON.stringify(defaultValues[key])
    );
  } catch (e) {
    console.error("Error retrieving stored positions", e);
    return defaultValues[key];
  }
}

export function setItem<T extends keyof LocalStorageKeys>(
  key: T,
  newValue: LocalStorageKeys[T]
): LocalStorageKeys[T] {
  localStorage.setItem(key, JSON.stringify(newValue));
  return newValue;
}
