import {
  IColumn,
  Label,
  Pivot,
  PivotItem,
  Shimmer,
  ShimmeredDetailsList,
  Stack,
  Toggle,
} from "@fluentui/react";
import {
  IVerticalStackedChartProps,
  VerticalStackedBarChart,
} from "@fluentui/react-charting";
import { FunctionComponent, useMemo, useState } from "react";
import { Position, TickerMap } from "../Models";
import { usePositionsContext } from "../Positions/PositionsContext";
import { useTickerContext } from "../Tickers/TickerContext";

const commonOptions = {
  isResizable: true,
  minWidth: 120,
  maxWidth: 240,
};

const columns: IColumn[] = [
  {
    ...commonOptions,
    fieldName: "symbol",
    key: "symbol",
    name: "Symbol",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "value",
    key: "value",
    name: "Value",
  },
  {
    ...commonOptions,
    fieldName: "exDividendDate",
    key: "exDividendDate",
    name: "Ex-dividend date",
  },
  {
    ...commonOptions,
    fieldName: "payoutDate",
    key: "payoutDate",
    name: "Payout date",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "dividendRate",
    key: "dividendRate",
    name: "Rate",
    onRender: (item: { dividendRate: number }) => {
      return Math.round(item.dividendRate * 10000) / 100 + "%";
    },
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "dividendValue",
    key: "dividendValue",
    name: "Dividend",
  },
];

const monthNames: Record<string, string> = {
  "-1": "Unknown",
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const getColor = (() => {
  const colorMap: Record<string, string> = {};
  return (symbol: string) => {
    if (!colorMap[symbol]) {
      colorMap[symbol] = `hsl(${Math.floor(Math.random() * 360)}, 60%, 80%)`;
    }
    return colorMap[symbol];
  };
})();

export const DividendIncomeView: FunctionComponent = () => {
  const { filters, positions } = usePositionsContext();
  const { currencyFormat, loading, tickerMap } = useTickerContext();
  const [byExDividend, setByExDividend] = useState<boolean>();
  const {
    chartItemsByExDividendDate,
    chartItemsByPayoutDate,
    listItems,
    total,
  } = useMemo(() => {
    return calculateData(
      currencyFormat,
      filters.dividendMetadata,
      positions,
      tickerMap
    );
  }, [currencyFormat, filters.dividendMetadata, positions, tickerMap]);

  const chartItems = byExDividend
    ? chartItemsByExDividendDate
    : chartItemsByPayoutDate;

  return (
    <>
      <Stack tokens={{ childrenGap: 12 }} horizontal>
        <section style={{ paddingLeft: 12 }}>
          <Label>Total dividends</Label>
          <Shimmer isDataLoaded={!loading}>
            {currencyFormat.format(total)}
          </Shimmer>
        </section>
        <Toggle
          checked={byExDividend}
          label="Chart mode"
          offText="By payout date"
          onText="By ex-dividend date"
          onChange={(_ev, checked) => setByExDividend(checked)}
        />
      </Stack>
      <Pivot>
        <PivotItem headerText="By month">
          <Shimmer isDataLoaded={!loading}>
            {chartItems.length ? (
              <VerticalStackedBarChart data={chartItems} />
            ) : (
              "No dividend data available"
            )}
          </Shimmer>
        </PivotItem>
        <PivotItem headerText="By symbol">
          <ShimmeredDetailsList
            enableShimmer={loading}
            columns={columns}
            items={listItems}
          />
        </PivotItem>
      </Pivot>
    </>
  );
};

function calculateData(
  currencyFormat: Intl.NumberFormat,
  dividendMetadata: string,
  positions: Position[],
  tickerMap: TickerMap
) {
  const exDividendDateMap: Record<string, Record<string, number>> = {};
  const payoutDateMap: Record<string, Record<string, number>> = {};
  const symbolMap: Record<
    string,
    {
      dividendRate: number;
      dividendValue: number;
      exDividendDate: Date;
      payoutDate: Date;
      value: number;
    }
  > = {};
  let total = 0;
  const frequencyMap = dividendMetadata
    .split(/\r\n|\n/)
    .reduce<Record<string, number>>((map, current) => {
      const parts = current.split("|");
      map[parts[0]] = parseInt(parts[1]);
      return map;
    }, {});

  for (const position of positions) {
    const ticker = tickerMap[position.symbol];
    const { dividendRate, exDividendDate, payoutDate } =
      (ticker && ticker.dividend) || {};
    if (dividendRate) {
      let exDividendMonth = exDividendDate
        ? new Date(exDividendDate * 1000).getMonth()
        : -1;
      let payoutMonth = payoutDate
        ? new Date(payoutDate * 1000).getMonth()
        : -1;
      const frequency = frequencyMap[position.symbol] || 3;
      const times = 12 / frequency;
      const annualDividend = position.quantity * dividendRate;
      const dividend = annualDividend / times;

      symbolMap[position.symbol] = symbolMap[position.symbol] || {
        dividendRate: dividendRate / ticker.price,
        dividendValue: 0,
        exDividendDate: toDateString(exDividendDate),
        payoutDate: toDateString(payoutDate),
        value: 0,
      };
      symbolMap[position.symbol].dividendValue += annualDividend;
      symbolMap[position.symbol].value += position.quantity * ticker.price;

      for (let i = 0; i < times; i++) {
        exDividendMonth = updateDateMap(
          exDividendDateMap,
          exDividendMonth,
          position,
          dividend,
          frequency
        );
        payoutMonth = updateDateMap(
          payoutDateMap,
          payoutMonth,
          position,
          dividend,
          frequency
        );
        total += dividend;
      }
    }
  }

  const chartItemsByExDividendDate = createChartItems(
    exDividendDateMap,
    symbolMap
  );
  const chartItemsByPayoutDate = createChartItems(payoutDateMap, symbolMap);
  const listItems = Object.keys(symbolMap)
    .sort()
    .map((symbol) => {
      return {
        symbol,
        ...symbolMap[symbol],
        dividendValue: currencyFormat.format(symbolMap[symbol].dividendValue),
        value: currencyFormat.format(symbolMap[symbol].value),
      };
    });
  return {
    chartItemsByExDividendDate,
    chartItemsByPayoutDate,
    listItems,
    total,
  };
}

function updateDateMap(
  dateMap: Record<string, Record<string, number>>,
  month: number,
  position: Position,
  dividend: number,
  frequency: number
) {
  const monthMap = (dateMap[month] = dateMap[month] || {});
  monthMap[position.symbol] = (monthMap[position.symbol] || 0) + dividend;
  return month !== -1 ? (month + frequency) % 12 : month;
}

function createChartItems(
  map: Record<string, Record<string, number>>,
  symbolMap: Record<string, { dividendRate: number; dividendValue: number }>
) {
  return Object.keys(map).map<IVerticalStackedChartProps>((month) => {
    const chartData = Object.keys(symbolMap)
      .sort()
      .map((symbol) => {
        return {
          color: getColor(symbol),
          data: map[month][symbol] || 0,
          legend: symbol,
        };
      });
    return {
      chartData,
      xAxisPoint: monthNames[month],
    };
  });
}

function toDateString(date: number | undefined) {
  return new Date((date || 0) * 1000).toLocaleDateString();
}
