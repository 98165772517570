import {
  IColumn,
  MessageBar,
  Pivot,
  PivotItem,
  ShimmeredDetailsList,
} from "@fluentui/react";
import { FunctionComponent, useMemo } from "react";
import { BrokerName } from "../Models";
import { useTickerContext } from "../Tickers/TickerContext";
import { usePositionsContext } from "./PositionsContext";

const commonOptions = {
  isResizable: true,
  minWidth: 120,
  maxWidth: 240,
};

const columns: IColumn[] = [
  {
    ...commonOptions,
    fieldName: "brokerName",
    key: "brokerName",
    name: "Broker",
  },
  {
    ...commonOptions,
    fieldName: "accountName",
    key: "accountName",
    name: "Account",
  },
  {
    ...commonOptions,
    fieldName: "symbol",
    key: "symbol",
    name: "Symbol",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "quantity",
    key: "quantity",
    name: "Quantity",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "price",
    key: "price",
    name: "Price",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "value",
    key: "value",
    name: "Value",
  },
];

export const PositionsView: FunctionComponent = () => {
  const { allPositionsMap, filters, positions } = usePositionsContext();
  const { currencyFormat, loading, tickerMap } = useTickerContext();
  const positionDetails = useMemo(() => {
    return positions.map((position) => {
      const ticker = tickerMap[position.symbol];
      return {
        ...position,
        ...ticker,
        price: ticker && currencyFormat.format(ticker.price),
        value:
          position &&
          ticker &&
          currencyFormat.format(position.quantity * ticker.price),
      };
    });
  }, [currencyFormat, positions, tickerMap]);

  const ignoredPositionDetails = useMemo(() => {
    if (!filters.enableIgnoredPositions) {
      return [];
    }

    return filters.ignoredPositionKeys.sort().map((key) => {
      const [brokerName, accountName, symbol] = key.split("/") as [
        BrokerName,
        string,
        string
      ];
      const position = allPositionsMap[brokerName]?.find(
        (c) => c.accountName === accountName && c.symbol === symbol
      ) || { brokerName, accountName, symbol, quantity: 0 };
      const ticker = tickerMap[position.symbol];
      return {
        ...position,
        ...ticker,
        price: ticker && currencyFormat.format(ticker.price),
        value:
          position &&
          ticker &&
          currencyFormat.format(position.quantity * ticker.price),
      };
    });
  }, [allPositionsMap, filters, tickerMap]);

  return (
    <Pivot>
      <PivotItem
        headerText="Filtered positions"
        itemCount={positionDetails.length}
      >
        <ShimmeredDetailsList
          columns={columns}
          compact
          enableShimmer={loading}
          items={positionDetails}
        />
      </PivotItem>
      <PivotItem
        headerText="Ignored positions"
        itemCount={ignoredPositionDetails.length}
      >
        {filters.enableIgnoredPositions ? (
          <ShimmeredDetailsList
            columns={columns}
            compact
            enableShimmer={loading}
            items={ignoredPositionDetails}
          />
        ) : (
          <div style={{ margin: 16 }}>
            <MessageBar>
              Ignored positions has been disabled in settings
            </MessageBar>
          </div>
        )}
      </PivotItem>
    </Pivot>
  );
};
