import {
  Dropdown,
  IconButton,
  IDropdownOption,
  ITextField,
  Panel,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import {
  FormEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { BrokerName } from "../Models";
import { usePositionsContext } from "../Positions/PositionsContext";
import { brokerNames, parsePositions } from "./BrokerManagement";

export interface ImportPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const fileUploadBrokers: BrokerName[] = ["CharlesSchwab", "Fidelity"];
const robinhoodScriptText = `copy(document.evaluate('//div[@class="main-container"]//span[text()="Brokerage Cash"]', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue.parentElement.parentElement.innerText + "|" + Array.from(document.body.getElementsByTagName("a")).filter(a => a.href.startsWith("https://robinhood.com/stocks/")).map(a => a.innerText).join("|") + "|" + Array.from(document.body.getElementsByTagName("a")).filter(a => a.href.startsWith("https://robinhood.com/crypto/")).map(a => "crypto:" + a.innerText).join("|"))`;

export const ImportAccountsPanel: FunctionComponent<ImportPanelProps> = ({
  isOpen,
  onDismiss,
}) => {
  const { setPositions } = usePositionsContext();
  const { brokerOptions, defaultName } = useMemo(() => {
    return {
      brokerOptions: brokerNames.map((key) => ({ key, text: key })),
      defaultName: brokerNames[0],
    };
  }, [brokerNames]);
  const fileInput = useRef<ITextField>(null);
  const [brokerName, setBrokerName] = useState<BrokerName>(defaultName);
  const [csvText, setCsvText] = useState<string>();

  const onBrokerDropdownChange = useCallback(
    (_ev: FormEvent, opt?: IDropdownOption) => {
      const newBrokerName = (opt && (opt.key as BrokerName)) || defaultName;
      setBrokerName(newBrokerName);
      setCsvText("");
    },
    [setBrokerName, setCsvText]
  );
  const onBrokerFileChange = useCallback(
    async (ev: FormEvent) => {
      const { files } = ev.target as HTMLInputElement;
      const file = files && files[0];
      if (file && file.size < 102400) {
        const newValue = await readTextFile(file);
        setCsvText(newValue);
      } else {
        setCsvText("");
      }
    },
    [setCsvText]
  );
  const onBrokerTextFieldChange = useCallback(
    (ev: FormEvent, newValue?: string) => {
      setCsvText(newValue);
    },
    [setCsvText]
  );
  const onImportButtonClick = useCallback(() => {
    if (csvText) {
      const positions = parsePositions(brokerName, csvText);
      setPositions({ brokerName, positions });
      onDismiss();
    }
  }, [brokerName, csvText, onDismiss]);
  const csvViaFileUpload = fileUploadBrokers.includes(brokerName);
  const onRobinhoodCopyClick = useCallback(() => {
    navigator.clipboard.writeText(robinhoodScriptText);
  }, []);

  return (
    <Panel isOpen={isOpen} onDismiss={onDismiss}>
      <Stack tokens={{ childrenGap: 16 }}>
        <Dropdown
          label="Broker"
          onChange={onBrokerDropdownChange}
          options={brokerOptions}
          selectedKey={brokerName}
        />
        {csvViaFileUpload ? (
          <TextField
            key="PositionsFileTextField"
            componentRef={fileInput}
            label="Positions file"
            onChange={onBrokerFileChange}
            type="file"
            disabled={!csvViaFileUpload}
          />
        ) : (
          <TextField
            key="PositionsCsvTextField"
            label="Positions CSV"
            multiline
            value={csvText}
            onChange={onBrokerTextFieldChange}
            disabled={csvViaFileUpload}
          />
        )}
        {brokerName === "Robinhood" && (
          <TextField
            readOnly
            value={robinhoodScriptText}
            onRenderSuffix={() => (
              <IconButton
                iconProps={{ iconName: "Copy" }}
                onClick={onRobinhoodCopyClick}
              />
            )}
          />
        )}
        <PrimaryButton
          disabled={!csvText}
          onClick={onImportButtonClick}
          text="Import"
        />
      </Stack>
    </Panel>
  );
};
async function readTextFile(file: File): Promise<string> {
  const reader = new FileReader();
  reader.readAsText(file);
  return new Promise<string>((resolve, reject) => {
    reader.onload = (evt) => {
      if (evt.target) {
        resolve(evt.target.result as string);
      }
    };
    reader.onerror = (evt) => {
      let message: string;
      if (evt.target) {
        message = (evt.target.error || "").toString();
      } else {
        message = "Can't read file.";
      }

      console.error(message);
      reject(message);
    };
  });
}
