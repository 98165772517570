import {
  CommandBar,
  ICommandBarItemProps,
  Label,
  makeStyles,
  Shimmer,
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FunctionComponent, useMemo } from "react";
import { ImportAccountsPanel } from "./Accounts/ImportAccountsPanel";
import { usePositionsContext } from "./Positions/PositionsContext";
import { SettingsPanel } from "./SettingsPanel";
import { useTickerContext } from "./Tickers/TickerContext";

const useStyles = makeStyles({
  appTopBarCss: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  appTopBarRightCss: {
    display: "flex",
    alignItems: "center",
    minWidth: 160,
  },
  appHeaderCss: {
    paddingLeft: 10,
  },
});

export const AppTopBar: FunctionComponent = () => {
  const { positions } = usePositionsContext();
  const { currencyFormat, loading, refresh, tickerMap } = useTickerContext();
  const value = useMemo(() => {
    const result = positions.reduce((sum, position) => {
      const ticker = tickerMap[position.symbol];
      return sum + (ticker ? position.quantity * ticker.price : 0);
    }, 0);
    return currencyFormat.format(result);
  }, [currencyFormat, positions, tickerMap]);
  const [
    isImportOpen,
    { setTrue: onImportPanelOpen, setFalse: onImportPanelDismiss },
  ] = useBoolean(false);
  const [
    isSettingsOpen,
    { setTrue: onSettingsPanelOpen, setFalse: onSettingsPanelDismiss },
  ] = useBoolean(false);
  const commands = useMemo<ICommandBarItemProps[]>(() => {
    return [
      {
        iconProps: { iconName: "Refresh" },
        key: "RefreshButton",
        onClick: refresh,
        text: "Refresh",
      },
      {
        iconProps: { iconName: "Upload" },
        key: "ImportPanelButton",
        onClick: onImportPanelOpen,
        text: "Import",
      },
      {
        iconProps: { iconName: "Settings" },
        key: "SettingsPanelButton",
        onClick: onSettingsPanelOpen,
        text: "Settings",
      },
    ];
  }, [onImportPanelOpen]);
  const { appTopBarCss, appHeaderCss, appTopBarRightCss } = useStyles();

  return (
    <>
      <Stack horizontal className={appTopBarCss}>
        <header className={appHeaderCss}>Portfolio Analyzer</header>
        <Stack className={appTopBarRightCss} horizontal>
          <section>
            <Label>Portfolio value</Label>
            <Shimmer isDataLoaded={!loading}>{value}</Shimmer>
          </section>
          <CommandBar items={commands} />
        </Stack>
      </Stack>
      <ImportAccountsPanel
        isOpen={isImportOpen}
        onDismiss={onImportPanelDismiss}
      />
      <SettingsPanel
        isOpen={isSettingsOpen}
        onDismiss={onSettingsPanelDismiss}
      />
    </>
  );
};
