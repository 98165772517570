import {
  DefaultPalette,
  IColumn,
  Pivot,
  PivotItem,
  Shimmer,
  ShimmeredDetailsList,
} from "@fluentui/react";
import {
  GroupedVerticalBarChart,
  IGroupedVerticalBarChartData,
} from "@fluentui/react-charting";
import { FunctionComponent, useMemo } from "react";
import { useTickerContext } from "../Tickers/TickerContext";
import { PortfolioSlice, usePortfolioSlices } from "./UsePortfolioSlices";

const commonOptions = {
  isResizable: true,
  minWidth: 160,
};

interface PortfolioSliceListItem extends PortfolioSlice {
  currencyFormat: Intl.NumberFormat;
}

const columns: IColumn[] = [
  {
    ...commonOptions,
    fieldName: "name",
    key: "name",
    name: "Name",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "symbols",
    key: "symbols",
    name: "Symbols",
    onRender: (item: PortfolioSliceListItem) => {
      const topSymbolsByValue = Object.keys(item.symbols)
        .sort((a, b) => item.symbols[b] - item.symbols[a])
        .slice(0, item.name === "Unknown" ? 30 : 3);
      return (
        <>
          {topSymbolsByValue.map((symbol) => (
            <div
              key={symbol}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 4,
                borderBottom: "1px solid #ddd",
              }}
            >
              <span>{symbol}</span>
              <span>{item.currencyFormat.format(item.symbols[symbol])}</span>
            </div>
          ))}
        </>
      );
    },
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "desired",
    key: "desired",
    name: "Desired value",
    onRender: (item: PortfolioSliceListItem) => {
      return renderValue(item, item.desired);
    },
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "actual",
    key: "actual",
    name: "Actual value",
    onRender: (item: PortfolioSliceListItem) => {
      return renderValue(item, item.actual);
    },
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "buy",
    key: "buy",
    name: "Buy amount",
    onRender: (item: PortfolioSliceListItem) => {
      return item.currencyFormat.format(item.buy);
    },
  },
];

export const InvestmentCheckupView: FunctionComponent = () => {
  const { marketCaps, regions, sectors } = usePortfolioSlices();
  const { currencyFormat, loading } = useTickerContext();
  const {
    marketCapChartData,
    marketCapList,
    regionChartData,
    regionList,
    sectorChartData,
    sectorList,
  } = useMemo(() => {
    return {
      marketCapChartData: toChartData(
        marketCaps,
        DefaultPalette.blue,
        DefaultPalette.blueLight
      ),
      marketCapList: toListData(marketCaps, currencyFormat),
      regionChartData: toChartData(
        regions,
        DefaultPalette.orange,
        DefaultPalette.orangeLighter
      ),
      regionList: toListData(regions, currencyFormat),
      sectorChartData: toChartData(
        sectors,
        DefaultPalette.green,
        DefaultPalette.greenLight
      ),
      sectorList: toListData(sectors, currencyFormat),
    };
  }, [currencyFormat, marketCaps, regions, sectors]);

  return (
    <Pivot>
      <PivotItem headerText="By sector">
        <ShimmeredDetailsList
          columns={columns}
          enableShimmer={loading}
          items={sectorList}
        />
      </PivotItem>
      <PivotItem headerText="By sector (chart)">
        <Shimmer isDataLoaded={!loading}>
          <GroupedVerticalBarChart data={sectorChartData} />
        </Shimmer>
      </PivotItem>
      <PivotItem headerText="By market cap">
        <ShimmeredDetailsList
          columns={columns}
          enableShimmer={loading}
          items={marketCapList}
        />
      </PivotItem>
      <PivotItem headerText="By market cap (chart)">
        <Shimmer isDataLoaded={!loading}>
          <GroupedVerticalBarChart data={marketCapChartData} />
        </Shimmer>
      </PivotItem>
      <PivotItem headerText="By region">
        <ShimmeredDetailsList
          columns={columns}
          enableShimmer={loading}
          items={regionList}
        />
      </PivotItem>
      <PivotItem headerText="By region (chart)">
        <Shimmer isDataLoaded={!loading}>
          <GroupedVerticalBarChart data={regionChartData} />
        </Shimmer>
      </PivotItem>
    </Pivot>
  );
};

function toListData(
  slices: PortfolioSlice[],
  currencyFormat: Intl.NumberFormat
) {
  return slices.map<PortfolioSliceListItem>((slice) => {
    return { ...slice, currencyFormat };
  });
}

function toChartData(
  slices: PortfolioSlice[],
  desiredColor: string,
  actualColor: string
) {
  return slices.map<IGroupedVerticalBarChartData>((slice) => {
    return {
      name: slice.name,
      series: [
        {
          key: "desired",
          legend: "Desired",
          data: slice.desired,
          color: desiredColor,
        },
        {
          key: "actual",
          legend: "Actual",
          data: slice.actual,
          color: actualColor,
        },
      ],
    };
  });
}

function renderValue(item: PortfolioSliceListItem, value: number) {
  const total = item.desired / item.percentage;
  return `(${
    value ? (total ? Math.round((value * 10000) / total) / 100 : "?") : 0
  }%) ${item.currencyFormat.format(value)}`;
}
