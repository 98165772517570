import { IColumn, ShimmeredDetailsList } from "@fluentui/react";
import { FunctionComponent } from "react";
import { Account } from "../Models";

interface AccountsViewProps {
  accounts: AccountItem[];
  loading: boolean;
}

interface AccountItem extends Account {
  positionsCount: number;
  value: string;
}

const commonOptions = {
  isResizable: true,
  minWidth: 120,
};

const columns: IColumn[] = [
  {
    ...commonOptions,
    fieldName: "brokerName",
    key: "brokerName",
    name: "Broker",
  },
  {
    ...commonOptions,
    fieldName: "accountName",
    key: "accountName",
    name: "Account",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "positionsCount",
    key: "positionsCount",
    name: "Positions",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "value",
    key: "value",
    name: "Value",
  },
];

export const AccountsView: FunctionComponent<AccountsViewProps> = ({
  accounts,
  loading,
}) => (
  <ShimmeredDetailsList
    enableShimmer={loading}
    columns={columns}
    compact
    items={accounts}
  />
);
