import { FunctionComponent, useCallback, useMemo, useReducer } from "react";
import { getItem, setItem } from "../LocalStorage";
import { AccountsMap, BrokerName, Position, PositionsState } from "../Models";
import {
  PositionsContext,
  SetFiltersParams,
  SetPositionsParams,
} from "./PositionsContext";

type PositionsProviderAction =
  | { type: "SetPositions"; value: SetPositionsParams }
  | { type: "SetFilters"; value: SetFiltersParams };

const initialState: PositionsState = {
  allPositionsMap: getItem("PortfolioAnalyzer_Positions"),
  filters: getItem("PortfolioAnalyzer_Filters"),
};

export const PositionsProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state: PositionsState, action: PositionsProviderAction) => {
      let newState = state;
      if (action.type === "SetPositions") {
        newState = {
          ...state,
          allPositionsMap: setItem("PortfolioAnalyzer_Positions", {
            ...state.allPositionsMap,
            [action.value.brokerName]: action.value.positions,
          }),
        };
      } else if (action.type === "SetFilters") {
        newState = {
          ...state,
          filters: setItem("PortfolioAnalyzer_Filters", action.value),
        };
      }

      return newState;
    },
    initialState
  );

  const [allAccountsMap, positions] = useMemo(() => {
    const { allPositionsMap, filters } = state;
    const selectedAccountKeys = filters.selectedAccountsKeys;
    const selectedAccountsMap = selectedAccountKeys.reduce((map, current) => {
      const [brokerName, accountName] = current.split("/") as [
        BrokerName,
        string
      ];
      (map[brokerName] = map[brokerName] || {})[accountName] = true;
      return map;
    }, {} as Partial<Record<BrokerName, Record<string, boolean>>>);
    const ignoredPositionsMap = filters.ignoredPositionKeys.reduce(
      (result, current) => {
        const [brokerName, accountName, symbol] = current.split("/") as [
          BrokerName,
          string,
          string
        ];
        const accounts = (result[brokerName] = result[brokerName] || {});
        (accounts[accountName] = accounts[accountName] || {})[symbol] = true;
        return result;
      },
      {} as Partial<Record<BrokerName, Record<string, Record<string, boolean>>>>
    );

    const newAllAccountsMap: AccountsMap = {};

    const newPositions: Position[] = [];

    for (const key in allPositionsMap) {
      const brokerName = key as BrokerName;
      const brokerPositions = allPositionsMap[brokerName];
      if (brokerPositions) {
        const accounts = (newAllAccountsMap[brokerName] =
          newAllAccountsMap[brokerName] || []);
        for (const position of brokerPositions) {
          const accountName = position.accountName;
          if (!accounts.some((c) => c.accountName === accountName)) {
            accounts.push({ brokerName, accountName });
          }
          const isSelectedAccount =
            !selectedAccountKeys.length ||
            selectedAccountsMap[brokerName]?.[accountName];
          const isIgnoredPosition =
            ignoredPositionsMap[brokerName]?.[accountName]?.[position.symbol];
          if (
            (!filters.enableIgnoredPositions || !isIgnoredPosition) &&
            (!filters.enableSelectedAccounts || isSelectedAccount)
          ) {
            newPositions.push(position);
          }
        }
      }
    }

    return [newAllAccountsMap, newPositions];
  }, [state]);

  const setPositions = useCallback(
    (value: SetPositionsParams) => dispatch({ type: "SetPositions", value }),
    [dispatch]
  );
  const setFilters = useCallback(
    (value: SetFiltersParams) => dispatch({ type: "SetFilters", value }),
    [dispatch]
  );

  return (
    <PositionsContext.Provider
      value={{ ...state, allAccountsMap, positions, setFilters, setPositions }}
    >
      {children}
    </PositionsContext.Provider>
  );
};
