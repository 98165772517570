import { createContext, Dispatch, useContext } from "react";
import {
  AccountsMap,
  BrokerName,
  FiltersState,
  Position,
  PositionsMap,
} from "../Models";

export type SetFiltersParams = FiltersState;

export interface SetPositionsParams {
  brokerName: BrokerName;
  positions: Position[];
}

const defaultContext = {
  allAccountsMap: {} as AccountsMap,
  allPositionsMap: {} as PositionsMap,
  filters: {
    dividendMetadata: "",
    enableIgnoredPositions: true,
    enableSelectedAccounts: true,
    ignoredPositionKeys: [],
    selectedAccountsKeys: [],
  } as FiltersState,
  positions: [] as Position[],
  setFilters: (() => ({})) as Dispatch<SetFiltersParams>,
  setPositions: (() => ({})) as Dispatch<SetPositionsParams>,
};

export const PositionsContext = createContext(defaultContext);

export function usePositionsContext(): typeof defaultContext {
  return useContext(PositionsContext);
}
