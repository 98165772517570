import { FunctionComponent, useCallback, useReducer, useState } from "react";
import { Position, TickerMap } from "../Models";
import { fetchTickers as fetchTickersApi } from "./TickerClient";
import { TickerContext } from "./TickerContext";

export const TickerProvider: FunctionComponent = ({ children }) => {
  const [tickerMap, setTickers] = useReducer(
    (state: TickerMap, action: TickerMap) => {
      if (!action) {
        console.error("Invalid inputs provided to 'setPositions' callback.");
        return state;
      }

      return {
        ...state,
        ...action,
      };
    },
    {}
  );
  const [loading, setLoading] = useState(true);

  const fetchTickers = useCallback(
    async (positions: Position[], refresh?: boolean) => {
      const pending = positions.filter((c) => refresh || !tickerMap[c.symbol]);
      if (pending.length) {
        setLoading(true);
        const pendingFromServer = pending.filter(
          (c) => !c.isCash && !c.isCrypto && !c.ticker
        );
        const updates = await fetchTickersApi(
          pendingFromServer.map(getServerSymbol)
        );
        for (const pendingPosition of pending) {
          if (pendingPosition.ticker) {
            updates[pendingPosition.symbol] = pendingPosition.ticker;
          }
          if (pendingPosition.isCash) {
            updates[pendingPosition.symbol] = {
              dividend: {},
              tickerName: pendingPosition.symbol,
              price: 1,
            };
          }
          const serverSymbol = getServerSymbol(pendingPosition);
          if (pendingPosition.symbol !== serverSymbol) {
            updates[pendingPosition.symbol] = updates[serverSymbol];
          }
        }
        setTickers(updates);
      }

      setLoading(false);
    },
    [setLoading, tickerMap, setTickers]
  );
  return (
    <TickerContext.Provider value={{ tickerMap, loading, fetchTickers }}>
      {children}
    </TickerContext.Provider>
  );
};

function getServerSymbol(position: Position) {
  if (
    position.brokerName === "Fidelity" &&
    position.accountName === "89766" &&
    position.symbol === "09259A361"
  ) {
    return "STLFX";
  }

  return position.symbol;
}
