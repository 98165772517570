import { useMemo } from "react";
import { Position, Ticker, TickerMap, TickerSlice } from "../Models";
import { usePositionsContext } from "../Positions/PositionsContext";
import { useTickerContext } from "../Tickers/TickerContext";

export interface PortfolioSlice extends Metadata {
  symbols: Record<string, number>;
  desired: number;
  actual: number;
  buy: number;
}

interface Metadata {
  name: string;
  percentage: number;
}

interface UsePortfolioSlicesResult {
  marketCaps: PortfolioSlice[];
  regions: PortfolioSlice[];
  sectors: PortfolioSlice[];
}

type SliceMap = Record<string, PortfolioSlice>;

const marketCapMetadata: Metadata[] = [
  { name: "Alternatives", percentage: 0.09 },
  { name: "Bonds", percentage: 0.07 },
  { name: "Cash", percentage: 0.01 },
  { name: "Crypto", percentage: 0.03 },
  { name: "Small cap", percentage: 0.2 },
  { name: "Mid cap", percentage: 0.2 },
  { name: "Large cap", percentage: 0.4 },
  { name: "Unknown", percentage: 0 },
];

const regionMetadata: Metadata[] = [
  { name: "Alternatives", percentage: 0.09 },
  { name: "Bonds", percentage: 0.07 },
  { name: "Cash", percentage: 0.01 },
  { name: "Crypto", percentage: 0.03 },
  { name: "North America", percentage: 0.56 },
  { name: "International", percentage: 0.24 },
  { name: "Unknown", percentage: 0 },
];

const sectorMetadata: Metadata[] = [
  { name: "Alternatives", percentage: 0.09 },
  { name: "Bonds", percentage: 0.07 },
  { name: "Cash", percentage: 0.01 },
  { name: "Crypto", percentage: 0.03 },
  { name: "Basic Materials", percentage: 0.04 },
  { name: "Communication Services", percentage: 0.06 },
  { name: "Consumer Cyclical", percentage: 0.095 },
  { name: "Consumer Defensive", percentage: 0.07 },
  { name: "Energy", percentage: 0.05 },
  { name: "Financial Services", percentage: 0.105 },
  { name: "Healthcare", percentage: 0.11 },
  { name: "Industrials", percentage: 0.07 },
  { name: "Technology", percentage: 0.16 },
  { name: "Utilities", percentage: 0.04 },
  { name: "Unknown", percentage: 0 },
];

const marketCapNormalization: Record<string, string> = {
  "Medium cap": "Mid cap",
};

const regionNormalization: Record<string, string> = {};

const sectorNormalization: Record<string, string> = {
  realestate: "Alternatives",
  "Real Estate": "Alternatives",
  basic_materials: "Basic Materials",
  communication_services: "Communication Services",
  consumer_cyclical: "Consumer Cyclical",
  consumer_defensive: "Consumer Defensive",
  energy: "Energy",
  financial_services: "Financial Services",
  healthcare: "Healthcare",
  industrials: "Industrials",
  technology: "Technology",
  utilities: "Utilities",
};

export const usePortfolioSlices = (): UsePortfolioSlicesResult => {
  const { positions } = usePositionsContext();
  const { tickerMap } = useTickerContext();

  return useMemo(
    () => calculateSlices(positions, tickerMap),
    [positions, tickerMap]
  );
};

function calculateSlices(positions: Position[], tickerMap: TickerMap) {
  const marketCapMap = marketCapMetadata.reduce(ensureSlice, {});
  const regionMap = regionMetadata.reduce(ensureSlice, {});
  const sectorMap = sectorMetadata.reduce(ensureSlice, {});

  let portfolioValue = 0;

  for (const position of positions) {
    const ticker = tickerMap[position.symbol];
    if (ticker) {
      const positionValue = ticker.price * position.quantity;
      portfolioValue += positionValue;

      if (position.isCash) {
        updateSlice(marketCapMap, "Cash", positionValue, position);
        updateSlice(regionMap, "Cash", positionValue, position);
        updateSlice(sectorMap, "Cash", positionValue, position);
      } else {
        processSlices(
          marketCapMap,
          ticker.marketCaps,
          positionValue,
          position,
          ticker,
          marketCapNormalization
        );
        processSlices(
          regionMap,
          ticker.regions,
          positionValue,
          position,
          ticker,
          regionNormalization
        );
        processSlices(
          sectorMap,
          ticker.sectors,
          positionValue,
          position,
          ticker,
          sectorNormalization
        );
      }
    }
  }

  const marketCaps = toList(marketCapMap, portfolioValue);
  const regions = toList(regionMap, portfolioValue);
  const sectors = toList(sectorMap, portfolioValue);

  return { marketCaps, regions, sectors };
}

function processSlices(
  sliceMap: SliceMap,
  slices: TickerSlice[] | undefined,
  totalValue: number,
  position: Position,
  ticker: Ticker,
  normalization: Record<string, string>
) {
  let unknownValue = totalValue;

  for (const slice of slices || []) {
    const sliceName = normalization[slice.name] || slice.name;
    const sliceValue = totalValue * slice.percentage;
    updateSlice(sliceMap, sliceName, sliceValue, position);
    unknownValue -= sliceValue;
  }

  if (unknownValue > 0) {
    updateSlice(sliceMap, "Unknown", unknownValue, position);
  } else if (unknownValue / totalValue < -0.0002) {
    console.error(`Market caps > 100%: ${position.symbol}`, position, ticker);
  }
}

function toList(map: SliceMap, total: number) {
  return Object.keys(map).map<PortfolioSlice>((name) => {
    const sliceItem = map[name];
    const desired = total * sliceItem.percentage;
    return {
      name,
      percentage: sliceItem.percentage,
      symbols: sliceItem.symbols,
      desired,
      actual: sliceItem.actual,
      buy: desired - sliceItem.actual,
    };
  });
}

function updateSlice(
  sliceMap: SliceMap,
  name: string,
  value: number,
  { symbol }: Position
) {
  ensureSlice(sliceMap, { name, percentage: 0 });
  const item = sliceMap[name];
  item.actual += value;
  item.symbols[symbol] = (item.symbols[symbol] || 0) + value;
}

function ensureSlice(map: SliceMap, { name, percentage }: Metadata) {
  map[name] = map[name] || { name, percentage, symbols: {}, actual: 0 };
  return map;
}
