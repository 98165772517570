import { createContext, useCallback, useContext, useEffect } from "react";
import { Position, TickerMap } from "../Models";
import { usePositionsContext } from "../Positions/PositionsContext";

export const TickerContext = createContext({
  loading: true,
  tickerMap: {} as TickerMap,
  // eslint-disable-next-line
  fetchTickers: (tickerNames: Position[], refresh?: boolean): void => {
    // No-op
  },
});

const currencyFormat = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "USD",
});

interface TickerContextResult {
  currencyFormat: Intl.NumberFormat;
  loading: boolean;
  refresh: () => void;
  tickerMap: TickerMap;
}

export function useTickerContext(): TickerContextResult {
  const { loading, tickerMap, fetchTickers } = useContext(TickerContext);
  const { positions } = usePositionsContext();
  useEffect(() => fetchTickers(positions), [fetchTickers, positions]);
  const refresh = useCallback(
    () => fetchTickers(positions, true),
    [fetchTickers, positions]
  );
  return { currencyFormat, loading, refresh, tickerMap };
}
