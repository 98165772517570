import { Pivot, PivotItem } from "@fluentui/react";
import { FunctionComponent, useMemo } from "react";
import { AccountsView } from "./Accounts/AccountsView";
import { DividendIncomeView } from "./DividendIncome/DividendIncomeView";
import { InvestmentCheckupView } from "./InvestmentCheckup/InvestmentCheckupView";
import { BrokerName } from "./Models";
import { usePositionsContext } from "./Positions/PositionsContext";
import { PositionsView } from "./Positions/PositionsView";
import { RebalanceView } from "./Rebalance/RebalanceView";
import { useTickerContext } from "./Tickers/TickerContext";

export const AppContent: FunctionComponent = () => {
  const { positions } = usePositionsContext();
  const { currencyFormat, loading, tickerMap } = useTickerContext();

  const accounts = useMemo(() => {
    const map: Record<string, { positionsCount: number; value: number }> = {};
    for (const position of positions) {
      const ticker = tickerMap[position.symbol];
      if (ticker) {
        const key = position.brokerName + "/" + position.accountName;
        map[key] = map[key] || { positionsCount: 0, value: 0 };
        map[key].positionsCount++;
        map[key].value += position.quantity * ticker.price;
      }
    }
    return Object.keys(map).map((key) => {
      const [brokerName, accountName] = key.split("/") as [BrokerName, string];
      return {
        brokerName,
        accountName,
        positionsCount: map[key].positionsCount,
        value: currencyFormat.format(map[key].value),
      };
    });
  }, [currencyFormat, positions, tickerMap]);
  return (
    <Pivot styles={{ link: { marginRight: 0 } }}>
      <PivotItem headerText="Positions" itemCount={positions.length}>
        <PositionsView />
      </PivotItem>
      <PivotItem headerText="Accounts" itemCount={accounts.length}>
        <AccountsView accounts={accounts} loading={loading} />
      </PivotItem>
      <PivotItem headerText="Rebalance">
        <RebalanceView />
      </PivotItem>
      <PivotItem headerText="Investment checkup">
        <InvestmentCheckupView />
      </PivotItem>
      <PivotItem headerText="Dividend income">
        <DividendIncomeView />
      </PivotItem>
    </Pivot>
  );
};
