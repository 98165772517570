import { Ticker, TickerMap } from "../Models";

interface TickersResponseBody {
  portfolio: {
    tickers: Ticker[];
  };
}

const tickerInfosQuery = `query TickerInfos($tickerNames: [String!]!) {
  portfolio {
    tickers(tickerNames: $tickerNames) {
      country
      dividend {
        dividendRate
        exDividendDate
        payoutDate
      }
      issueType
      marketCaps {
        name
        percentage
      }
      price
      regions {
        name
        percentage
      }
      sectors {
        name
        percentage
      }
      tickerName
    }
  }
}`;

export async function fetchTickers(tickerNames: string[]): Promise<TickerMap> {
  const map: TickerMap = {};
  if (tickerNames.length) {
    const response = await queryJson<TickersResponseBody>(tickerInfosQuery, {
      tickerNames,
    });
    response.portfolio.tickers.forEach((ticker) => {
      map[ticker.tickerName] = ticker;
    });
  }

  return map;
}

async function queryJson<T>(query: string, variables: Record<string, unknown>) {
  const response = await fetch("https://robotix1986-api.azurewebsites.net/", {
    body: JSON.stringify({ query, variables }),
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  });
  const json: { data: T } = await response.json();
  return json.data;
}
