import { BrokerName, Position } from "../Models";

interface BrokerHandler {
  processInput(inputText: string): Position[];
}

const brokerMap: Record<BrokerName, BrokerHandler> = {
  CharlesSchwab: {
    processInput: (csvText: string) => {
      const csv = parseCsv(csvText);

      const newPositions: Position[] = [];
      let positions: null | Position[] = null;
      let readHeader = false;
      let account = "";
      for (let i = 1; i < csv.length; i++) {
        const current = csv[i];
        if (!positions) {
          if (current.length === 1) {
            positions = [];
            readHeader = true;
            account = current[0];
          } else if (current.length) {
            throw new Error(
              "Unknown line format found between accounts: " + current.join()
            );
          }
        } else {
          if (current[0] === "Account Total") {
            newPositions.push(...positions);
            positions = null;
          } else if (readHeader) {
            readHeader = false;
          } else if (current.length === 26) {
            let symbol: string;
            let quantity: number;
            let isCash: true | undefined;
            if (current[0] === "Cash & Cash Investments") {
              symbol = "Cash";
              quantity = parseNumber(current[6]);
              isCash = true;
            } else {
              symbol = current[0];
              quantity = parseNumber(current[2]);
            }

            positions.push({
              accountName: account,
              brokerName: "CharlesSchwab",
              isCash,
              quantity,
              symbol,
            });
          } else {
            throw new Error(
              "Unknown line format found within account: " + current.join()
            );
          }
        }
      }

      return newPositions;
    },
  },
  Fidelity: {
    processInput: (csvText: string) => {
      const csv = parseCsv(csvText);
      const newPositions: Position[] = [];

      for (let i = 1; i < csv.length; i++) {
        const current = csv[i];

        if (!current.length) {
          break;
        }

        if (current[3] === "n/a") {
          continue;
        }

        const account = current[0];
        const symbol = current[1];
        const isCash =
          symbol === "FCASH**" || symbol === "CORE**" ? true : undefined;

        newPositions.push({
          accountName: account,
          brokerName: "Fidelity",
          isCash,
          quantity: parseNumber(current[3]),
          symbol,
        });
      }

      return newPositions;
    },
  },
  Fundrise: {
    processInput: (input: string) => {
      const accountName = "Fundrise-Default";
      const position: Position = {
        accountName,
        brokerName: "Fundrise",
        quantity: parseNumber(input),
        symbol: accountName,
        ticker: {
          dividend: {},
          price: 1,
          tickerName: accountName,
          sectors: [{ name: "Alternatives", percentage: 1 }],
        },
      };
      return [position];
    },
  },
  Robinhood: {
    processInput: (csvText: string) => {
      const newPositions = csvText.split("|").map((item) => {
        const parts = item.split(/\r\n|\n/);
        const isCash = parts[0] === "Brokerage Cash" ? true : undefined;
        const isCrypto = parts[0].startsWith("crypto:") ? true : undefined;
        const position: Position = {
          accountName: "default",
          brokerName: "Robinhood",
          isCash,
          isCrypto,
          quantity: parseNumber(parts[2]),
          symbol: isCash ? "Cash" : parts[1],
        };
        if (isCrypto) {
          position.ticker = {
            dividend: {},
            price: parseNumber(parts[3]),
            tickerName: parts[1],
            sectors: [{ name: "Crypto", percentage: 1 }],
          };
        }
        return position;
      });
      return newPositions;
    },
  },
};

export const brokerNames = Object.keys(brokerMap) as BrokerName[];

export function parsePositions(
  brokerName: BrokerName,
  csvText: string
): Position[] {
  return brokerMap[brokerName].processInput(csvText);
}

function parseCsv(text: string): string[][] {
  const rows = text.split(/\r\n|\n/).map((row) => {
    const entries: string[] = [];
    if (row) {
      let withinQuotes = false;
      let entry: string[] = [];
      row.split("").forEach((character) => {
        if (character === `"`) {
          withinQuotes = !withinQuotes;
        } else if (character === "," && !withinQuotes) {
          entries.push(entry.join(""));
          entry = [];
        } else {
          entry.push(character);
        }
      });
      entries.push(entry.join(""));
    }
    return entries;
  });
  return rows;
}

function parseNumber(input: string): number {
  return Number(input.replace(/[^0-9.-]+/g, ""));
}
