import {
  Dropdown,
  IColumn,
  IDropdownOption,
  ShimmeredDetailsList,
  Stack,
} from "@fluentui/react";
import {
  FormEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { getItem } from "../LocalStorage";
import { Position, TickerMap } from "../Models";
import { usePositionsContext } from "../Positions/PositionsContext";
import { useTickerContext } from "../Tickers/TickerContext";
import { Portfolio } from "./Portfolio";

const initialPortfolios = getItem("PortfolioAnalyzer_Portfolios") || [];

const commonOptions = {
  isResizable: true,
  minWidth: 160,
};

const columns: IColumn[] = [
  { ...commonOptions, fieldName: "sector", key: "sector", name: "Sector" },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "symbols",
    key: "symbols",
    name: "Symbols",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "desired",
    key: "desierd",
    name: "Desired value",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "actual",
    key: "actual",
    name: "Actual value",
  },
  {
    ...commonOptions,
    className: "detailslist-number",
    fieldName: "buy",
    key: "buy",
    name: "Buy amount",
  },
];

export const RebalanceView: FunctionComponent = () => {
  const { positions } = usePositionsContext();
  const { currencyFormat, loading, tickerMap } = useTickerContext();
  const [portfolioName, setPortfolioName] = useState<string | undefined>(
    initialPortfolios[0] && initialPortfolios[0].name
  );
  const [portfolios] = useState(initialPortfolios);

  const rows = useMemo(() => {
    const portfolio = portfolios.find((c) => c.name === portfolioName);
    if (!portfolio) {
      return [];
    }

    const { value, positionsMap } = calculatePositionMap(
      positions,
      tickerMap,
      portfolio
    );

    return portfolio.items.map((item) => {
      const desired = value * item.percentage;
      const categorySymbols =
        item.symbols[0] === "*"
          ? Object.keys(positionsMap).filter(
              (symbol) => !item.symbols.includes(symbol)
            )
          : item.symbols;
      const actual = categorySymbols
        .map((symbol) => positionsMap[symbol] || 0)
        .reduce((total, current) => total + current, 0);

      return {
        sector: item.category,
        symbols: categorySymbols.join(", "),
        desired: currencyFormat.format(desired),
        actual: currencyFormat.format(actual),
        buy: currencyFormat.format(desired - actual),
      };
    });
  }, [portfolioName, portfolios, positions, tickerMap]);

  const onPortfolioDropdownChange = useCallback(
    (_evt: FormEvent, option?: IDropdownOption) => {
      setPortfolioName(option && option.text);
    },
    [setPortfolioName]
  );

  return (
    <>
      <Stack horizontal style={{ paddingLeft: 12 }}>
        <Dropdown
          label="Portfolio name"
          options={portfolios.map(({ name }) => ({ key: name, text: name }))}
          selectedKey={portfolioName}
          onChange={onPortfolioDropdownChange}
        />
      </Stack>
      <ShimmeredDetailsList
        columns={columns}
        enableShimmer={loading}
        items={rows}
      />
    </>
  );
};

function calculatePositionMap(
  positions: Position[],
  tickerMap: TickerMap,
  portfolio: Portfolio
) {
  const positionsMap: Record<string, number> = {};
  let value = 0;

  for (const position of positions) {
    positionsMap[position.symbol] = positionsMap[position.symbol] || 0;
    const ticker = tickerMap[position.symbol];
    if (ticker) {
      const positionValue = position.quantity * ticker.price;
      positionsMap[position.symbol] += positionValue;
      value += positionValue;
    }
  }

  const externalItems = portfolio.items.filter((c) => c.external);
  let currentPercent =
    1 - externalItems.reduce((total, current) => total + current.percentage, 0);

  for (const externalItem of externalItems) {
    const externalValue = (value * externalItem.percentage) / currentPercent;
    const symbol = externalItem.symbols[0];
    positionsMap[symbol] = (positionsMap[symbol] || 0) + externalValue;
    value += externalValue;
    currentPercent += externalItem.percentage;
  }

  return { value, positionsMap };
}
