import {
  Dropdown,
  IDropdownOption,
  Panel,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import {
  FormEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { brokerNames } from "./Accounts/BrokerManagement";
import { usePositionsContext } from "./Positions/PositionsContext";

export interface SettingsPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const keyDelimiter = "/";

export const SettingsPanel: FunctionComponent<SettingsPanelProps> = ({
  isOpen,
  onDismiss,
}) => {
  const {
    allAccountsMap,
    allPositionsMap,
    filters: {
      dividendMetadata: initialDividendMetadata,
      enableIgnoredPositions: initialEnableIgnoredPositions,
      enableSelectedAccounts: initialEnableSelectedAccounts,
      ignoredPositionKeys: initialIgnoredPositionKeys,
      selectedAccountsKeys: initialSelectedAccountsKeys,
    },
    setFilters,
  } = usePositionsContext();
  const allAccountOptions = useMemo(() => {
    return brokerNames.reduce((result, brokerName) => {
      const accounts = allAccountsMap[brokerName];
      const options = (accounts || []).map<IDropdownOption>((account) => {
        const dropDownKey = brokerName + keyDelimiter + account.accountName;
        return { key: dropDownKey, text: dropDownKey };
      });
      result.push(...options);
      return result;
    }, [] as IDropdownOption[]);
  }, [allAccountsMap]);
  const allTickerOptions = useMemo(() => {
    return brokerNames.reduce<IDropdownOption[]>((result, brokerName) => {
      const positions = allPositionsMap[brokerName];
      const options = (positions || []).map<IDropdownOption>((position) => {
        return {
          key: `${position.brokerName}/${position.accountName}/${position.symbol}`,
          text: `${position.symbol} (${position.brokerName}/${position.accountName})`,
        };
      });
      result.push(...options);
      return result;
    }, []);
  }, [allPositionsMap]);
  const [selectedAccountsKeys, setSelectedAccountsKeys] = useState<string[]>(
    initialSelectedAccountsKeys
  );
  const [ignoredPositionKeys, setIgnoredTickerNames] = useState<string[]>(
    initialIgnoredPositionKeys
  );
  const [enableIgnoredPositions, setEnableIgnoredPositions] = useState(
    initialEnableIgnoredPositions
  );
  const [enableSelectedAccounts, setEnableSelectedAccounts] = useState(
    initialEnableSelectedAccounts
  );
  const [dividendMetadata, setDividendMetadata] = useState(
    initialDividendMetadata
  );
  const onSelectedAccountsChange = useCallback(
    (ev: FormEvent, option?: IDropdownOption) => {
      const newKeys = [...selectedAccountsKeys];
      let changed = false;
      if (option) {
        if (option.selected && !newKeys.includes(option.key as string)) {
          newKeys.push(option.key as string);
          changed = true;
        }

        if (!option.selected) {
          const removeIndex = newKeys.indexOf(option.key as string);
          if (removeIndex >= 0) {
            newKeys.splice(removeIndex, 1);
            changed = true;
          }
        }
      }
      if (changed) {
        setSelectedAccountsKeys(newKeys);
      }
    },
    [selectedAccountsKeys, setSelectedAccountsKeys]
  );
  const onIgnoredTickersChange = useCallback(
    (ev: FormEvent, option?: IDropdownOption) => {
      const newKeys = [...ignoredPositionKeys];
      let changed = false;
      if (option) {
        if (option.selected && !newKeys.includes(option.key as string)) {
          newKeys.push(option.key as string);
          changed = true;
        }

        if (!option.selected) {
          const removeIndex = newKeys.indexOf(option.key as string);
          if (removeIndex >= 0) {
            newKeys.splice(removeIndex, 1);
            changed = true;
          }
        }
      }
      if (changed) {
        setIgnoredTickerNames(newKeys);
      }
    },
    [ignoredPositionKeys, setIgnoredTickerNames]
  );
  const onEnableIgnoredPositionsChange = useCallback(
    (ev: FormEvent, checked?: boolean) => {
      setEnableIgnoredPositions(!!checked);
    },
    [enableIgnoredPositions]
  );
  const onEnableSelectedAccountsChange = useCallback(
    (ev: FormEvent, checked?: boolean) => {
      setEnableSelectedAccounts(!!checked);
    },
    [enableSelectedAccounts]
  );
  const onDividendMetadataChange = useCallback(
    (ev: FormEvent, newMetadata?: string) => {
      setDividendMetadata(newMetadata || "");
    },
    [setDividendMetadata]
  );
  const onSave = useCallback(() => {
    setFilters({
      dividendMetadata,
      enableIgnoredPositions,
      enableSelectedAccounts,
      ignoredPositionKeys,
      selectedAccountsKeys,
    });
    onDismiss();
  }, [
    setFilters,
    dividendMetadata,
    enableIgnoredPositions,
    enableSelectedAccounts,
    ignoredPositionKeys,
    selectedAccountsKeys,
    onDismiss,
  ]);

  return (
    <Panel isOpen={isOpen} onDismiss={onDismiss}>
      <Stack tokens={{ childrenGap: 16 }}>
        <Toggle
          label="Enable selected accounts"
          checked={enableSelectedAccounts}
          onChange={onEnableSelectedAccountsChange}
        />
        <Dropdown
          label="Selected accounts"
          disabled={!enableSelectedAccounts}
          multiSelect
          onChange={onSelectedAccountsChange}
          options={allAccountOptions}
          selectedKeys={selectedAccountsKeys}
        />
        <Toggle
          label="Enable ignored positions"
          checked={enableIgnoredPositions}
          onChange={onEnableIgnoredPositionsChange}
        />
        <Dropdown
          label="Ignored positions"
          disabled={!enableIgnoredPositions}
          multiSelect
          onChange={onIgnoredTickersChange}
          options={allTickerOptions}
          selectedKeys={ignoredPositionKeys}
        />
        <TextField
          label="Dividend metadata"
          multiline
          onChange={onDividendMetadataChange}
          value={dividendMetadata}
        />
        <PrimaryButton onClick={onSave} text="Save" />
      </Stack>
    </Panel>
  );
};
