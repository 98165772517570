import { FunctionComponent } from "react";
import { AppContent } from "./AppContent";
import { AppTopBar } from "./AppTopBar";
import { PositionsProvider } from "./Positions/PositionsProvider";
import { TickerProvider } from "./Tickers/TickerProvider";

const App: FunctionComponent = () => {
  return (
    <PositionsProvider>
      <TickerProvider>
        <AppTopBar />
        <AppContent />
      </TickerProvider>
    </PositionsProvider>
  );
};

export default App;
